/**
 * Created by Federico Brocco on 07/05/2022.
 *
 * @format
 */

let permission = function (hash) {
    return new Promise(function (resolve, reject) {

        const hashCheck = (hash !== null) ? hash : localStorage.getItem('hash');

        if (hashCheck === null) {
            reject("NO_AUTH");
            return;
        }

        fetch(global.route.permission, {
            headers: {'x-access-token': hashCheck},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                // global.checkAuth(responseJson.status);
                console.log('API permission', responseJson);
                if (responseJson.permission !== "Expired") {
                    sessionStorage.setItem("userData", JSON.stringify(responseJson.permission));
                    localStorage.setItem("hash", hashCheck);
                    resolve(responseJson);
                } else {
                    localStorage.removeItem("hash");
                    sessionStorage.removeItem("userData");
                    reject(responseJson);
                }
            })
            .catch((e) => {
                console.error(e.message);
                reject("NO_AUTH")
            });
    });
};

let login = function (credential) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.login, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                fiscal_code : credential.fiscal_code,
                password : credential.password
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log('API login', responseJson);
                if (typeof responseJson.session_token !== 'undefined') {
                    sessionStorage.setItem("userData", JSON.stringify(responseJson.user));
                    localStorage.setItem('hash', responseJson.session_token);
                    resolve(responseJson.user);
                } else {
                    localStorage.removeItem('hash');
                    sessionStorage.removeItem("userData");
                    reject(responseJson);
                }
            }).catch((e) => {
                console.error(e.message);
                reject(e);
            });
    });
};

let loginSPID = function () {
    return new Promise(function (resolve, reject) {
        let app_aggregator_url = 'https://utd-' + global.getMunicipalityString().replace('_','-') + '.megtributi.it';
        // console.log(app_aggregator_url);
        fetch(app_aggregator_url + "/utd/backoffice/v1/login_spid/", {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log('API login spid', responseJson);
                if (typeof responseJson.session_token !== 'undefined') {
                    //new                   
                    sessionStorage.setItem("userData", JSON.stringify(responseJson.user));
                    localStorage.setItem('hash', responseJson.session_token);
                    // resolve(responseJson.user);
                    //new
                    resolve(responseJson);
                } else {
                    localStorage.removeItem('hash');
                    sessionStorage.removeItem("userData");
                    reject(responseJson);
                }
            }).catch((e) => {
            console.error(e.message);
            reject(e);
        });
    });
};

const AuthAPI = {
    permission: permission,
    login: login,
    loginSPID: loginSPID
};

module.exports = AuthAPI;
