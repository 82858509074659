/**
 * Created by Federico Brocco on 30/08/2023.
 *
 * @format
 */

////////////////////
// PER MODULI ONLINE
////////////////////

let list = function () {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');
        if (hash === null) {
            reject("NO_AUTH");
            return;
        }

        resolve([])

        // fetch(global.route.messageTicket + global.getMunicipalityString(), {
        //     headers: {'x-access-token': hash},
        //     method: 'GET'
        // })
        //     .then((response) => (response.status !== 500) ? response.json() : reject())
        //     .then((responseJson) => {
        //         console.log('API ticket messages', responseJson.messages);
        //         resolve(responseJson.messages);
        //     })
        //     .catch((e) => {
        //         console.error(e.message);
        //         reject()
        //     });
    });
};

let sentList = function () {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');
        if (hash === null) {
            reject("NO_AUTH");
            return;
        }

        fetch(global.route.sentsModules, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API modules sent', responseJson);
                resolve(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let send = function (module) {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');
        if (hash === null) {
            reject("NO_AUTH");
            return;
        }

        fetch(global.route.sendModule, {
            headers: {'x-access-token': hash},
            method: 'POST',
            body: JSON.stringify({
                id_form_type: module.id,
                name_file_uploaded: module.name,
                file_uploaded: module.file,
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API send module', responseJson);
                resolve(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};



const ModulesAPI = {
    list: list,
    send: send,
    sentList: sentList
};

module.exports = ModulesAPI;
