const fakedb = require("../fakedb.json");
/**
 * Created by Federico Brocco on 13/05/2022.
 *
 * @format
 */

let list = function () {
    return new Promise(function (resolve, reject) {

        fetch(global.route.regolationsType, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API regulations list', responseJson);
                resolve(responseJson.regulation_types);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let detail = function (regulation_type_id) {
    return new Promise(function (resolve, reject) {

        fetch(global.route.regolationsDetail + "?regulation_type_id=" + regulation_type_id, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API regulations detail', responseJson);
                resolve(responseJson.regulations);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};



const RegulationsAPI = {
    list: list,
    detail: detail
};

module.exports = RegulationsAPI;
