/**
 * Created by Federico Brocco on 07/12/2023.
 *
 * @format
 */

let list = function () {
    return new Promise(function (resolve, reject) {

        fetch(global.route.lawsType, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API laws list', responseJson);
                resolve(responseJson.law_types);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let detail = function (law_type_id) {
    return new Promise(function (resolve, reject) {

        fetch(global.route.lawsDetail + "?law_type_id=" + law_type_id, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API law detail', responseJson);
                resolve(responseJson.laws);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};



const LawsAPI = {
    list: list,
    detail: detail
};

module.exports = LawsAPI;
