/**
 * Created by Federico Brocco on 13/05/2022.
 *
 * @format
 */

let list = function () {
    return new Promise(function (resolve, reject) {

        const hash = localStorage.getItem('hash');
        if (hash === null) {
            reject("NO_AUTH");
            return;
        }

        fetch(global.route.ticket, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API ticket', responseJson);
                resolve(responseJson.tickets);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let openTicket = function (ticket) {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');
        if (hash === null) {
            reject("NO_AUTH");
            return;
        }

        fetch(global.route.ticketBooking, {
            headers: {'x-access-token': hash},
            method: 'POST',
            body: JSON.stringify({
                id_category : ticket.id_category,
                object_: ticket.object
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API openTicket', responseJson);
                resolve(responseJson.ticket);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};


let messages = function (id) {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');
        if (hash === null) {
            reject("NO_AUTH");
            return;
        }

        fetch(global.route.messageTicket + "?ticket_id=" + id, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API ticket messages', responseJson.messages);
                resolve(responseJson.messages);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let sendMessage = function (ticket, attachment) {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');
        if (hash === null) {
            reject("NO_AUTH");
            return;
        }

        fetch(global.route.bookingMessageTicket, {
            headers: {'x-access-token': hash},
            method: 'POST',
            body: JSON.stringify({
                ticket_id : ticket.id,
                message: ticket.message,
                name_file_uploaded: (attachment !== null) ? attachment.name : undefined,
                file_uploaded: (attachment !== null) ? attachment.file : undefined,
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API sendMessage', responseJson);
                resolve(responseJson.message);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

const TicketAPI = {
    list: list,
    openTicket: openTicket,
    messages: messages,
    sendMessage: sendMessage
};

module.exports = TicketAPI;
