let server1 = process.env.REACT_APP_API_URL_1;
let server2 = process.env.REACT_APP_API_URL_2;

let route = {
  // auth
  login: '/login/',
  loginSPID: '/login_spid/',
  permission: '/permission/',

  // homepage
  homepage: '/homepage/',

  // payments
  payments: '/payments_notice/',

  // paymentsDetail
  paymentsDetail: '/payments/',

  // categories
  categories: '/categories/',

  // availability
  availability: '/availability/',

  // appointments
  appointments: '/appointments/',

  // booking
  booking: '/booking/',

  // modules
  sentsModules: '/forms/',
  sendModule: '/send_form/',
  modules: '/tipi_modulistica/',
  module_detail: '/modulistica/',

  // elenco ticket
  ticket: '/tickets/',

  // apertura ticket
  ticketBooking: '/booking_ticket/',

  // messaggi ticket
  messageTicket: '/messages/',

  // rilascia messaggio ticket
  bookingMessageTicket: '/booking_message/',

  // regolations
  regolationsType: "/regulation_types/",
  regolationsDetail: "/regulations/",

  // laws
  lawsType: "/law_types/",
  lawsDetail: "/laws/",

  // richiesta iuv
  bookingIuv: "/booking_iuv/",

  // invio richiesta occupazione suolo pubblico
  requestOccupation: '/request_occupation/',

  // news
  newsHomeList: '/news',

  //faq
  faqList: '/faq?categoria_id={{id_category}}&pagina',

  // installment
  installment: '/installment',
};

global.getMunicipalityStringUrl = function () {
  return window.location.pathname.split("/")[2].replace("_", "-");
}

for (let i in route) {
  route[i] = server1 + global.getMunicipalityStringUrl() + server2 + route[i];
}

global.route = route;

global.getMunicipalityString = function () {
  return window.location.pathname.split("/")[2];
}

module.export = route;
