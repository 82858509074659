import './routesAPI';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom'


import '../src/assets/css/bootstrap.min.css';
import '../src/assets/css/glightbox.min.css';
import '../src/assets/css/main.css';
import '../src/assets/css/custom.css';
import '../src/assets/css/LineIcons.2.0.css';

// import '../src/assets/js/wow.min.js';
import '../src/assets/js/main.js';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// import fakedb from "./fakedb.json";


global.SweetAlert = withReactContent(Swal);

global.checkLogin = function () {
    // const fakedb = require("./fakedb");
    // const hash = localStorage.getItem('hash');
    // if (hash === null)
    //     return false;
    //
    // for(let i = 0; i < fakedb.users.length; i++) {
    //     if (hash === fakedb.users[i].token) {
    //         global.userData = fakedb.users[i];
    //         return fakedb.users[i];
    //     }
    // }
    // localStorage.removeItem('hash');
    // return false;
}

global.delayConst = Math.floor(Math.random() * 300) + 100;

// import '../src/assets/js/main.js';

// ReactDOM.render(<App />, document.getElementById('root'));

if (process.env.NODE_ENV === "production") {
    console.log('%cDeveloped by Meg Tributi SPA', 'color: #4caf50; font-size: 18px; font-weight: bold;');

    console.log('\x1b[36mwww.megtributi.it');
    console.log = function() {};
    console.error = function() {};
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
