import React, {Component, useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import * as router from 'react-router-dom';
import {Container, Button, Badge, Alert, Row, Col, Input} from 'reactstrap';

import {Spinner, InstallmentModal} from '../components/';

import DataTable from 'react-data-table-component'
import {TicketAPI, PaymentsAPI, CategoriesAPI, InstallmentApi} from "../api";

import {LoginButton} from "../components";
import Select from "react-select";

import {Utils} from "../services";

const moment = require("moment")

class Payments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            payments: [],
            form: {
                id_category: null,
                name_category: "",
                object: "", 
                message: ""
            },
            formInstallment: {
                id_category: null,
                name_category: "",
                object: "",
                remaining_payment: 0,
                installment_nr: null,
                installment_expiration_id: 0,
                installment_expiration: [
                    {
                        'id': 1,
                        'value': '15 del mese'
                    },
                    {
                        'id': 2,
                        'value': 'ultimo del mese'
                    }
            ]
            },
            errorModal: null
        };
        this.paymentsHolder = [];
    }


    
    handleInstNum = (event) => {
        if (event.target.value < 1) {
            this.state.formInstallment.installment_nr = 1;
            event.target.value = '1';
        }else{
            this.state.formInstallment.installment_nr = event.target.value;
        }
    }

    resetFormInstallment = ()=>{
        this.setState({
            formInstallment: {
                id_category: null,
                name_category: "",
                object: "",
                remaining_payment: 0,
                installment_nr: null,
                installment_expiration: null
            }
        });
    }

    componentDidMount() {       
    
        if (Utils.isLoggedIn()) {
            PaymentsAPI.list().then((payments) => {
                let total = 0;
                for (let i = 0 ; i < payments.length; i++) {
                    total = total + payments[i].remaining_payment
                }

                this.paymentsHolder = payments;
                payments.push({remaining_payment: "Totale: " + Utils.getValueMoneyLabel(total), link_pagopa: null, url_doc: null})
                this.setState({loading: false, payments: payments});
            }).catch((e) => {
                this.setState({
                    error: true, loading: false
                })
            });
        } else {
            this.setState({loading: false, error: true});
        }
    }

    onSearchAdvise(text) {
        const newData = this.paymentsHolder.filter(item => {
            const itemData = `${item.iuv} ${item.object.toUpperCase()} ${String(item.total)} ${item.year}`;
            const textData = text.toUpperCase();
            return itemData.indexOf(textData) > -1;
        });
        this.setState({payments: newData});
    }

    getDetailsPayment(iuvs) {

            const columns = [
                {
                    name: 'Data',
                    sortable: true,
                    sortField: 'data_scadenza',
                    selector: row => row.data_scadenza,
                    cell: row => (
                        <div className='d-flex justify-content-left align-items-center'>
                            <div className='d-flex'>
                                {moment(row.data_scadenza).format("DD/MM/YYYY")}
                            </div>
                        </div>
                    )
                },
                {
                    name: 'Importo',
                    sortable: true,
                    sortField: 'total',
                    selector: row => row.total,
                    cell: row => (
                        <div className='d-flex justify-content-left align-items-center'>
                            <div className='d-flex'>
                                <strong>{Utils.getValueMoneyLabel(row.total)}</strong>
                            </div>
                        </div>
                    )
                },
                {
                    name: 'Stato',
                    sortable: true,
                    sortField: 'status',
                    selector: row => row.status,
                    cell: row => (
                        <div className='d-flex justify-content-left align-items-center'>
                            <Badge
                                className={'text-capitalize'}
                                color={(row.status === "Pagato") ? "success" : ((row.status === "Non Pagato") ? "danger" : "secondary")}>
                                {row.status}
                            </Badge>
                        </div>
                    )
                },
                {
                    name: 'N. rata',
                    sortable: true,
                    sortField: 'num_rata',
                    selector: row => row.num_rata,
                    cell: row => (
                        <div className='d-flex justify-content-left align-items-center'>
                            <Badge
                                className={'text-capitalize'}
                                color="secondary">
                                    {row.num_rata}
                            </Badge>
                        </div>
                    )

                },
            {
                name: '',
                sortable: false,
                maxWidth: "10px",
                minWidth: "10px",
                cell: row => (
                    <div>
                        <strong>{(row.url_doc_pagopa !== null) ?
                            <a href={row.url_doc_pagopa} target="_blank"><i className="fa fa-file-pdf-o text-danger"
                                                                     style={{fontSize: "20px", cursor: "pointer"}}
                                                                     title="Visualizza PagoPa"/></a> : null}</strong>
                    </div>
                )
            },
                {
                    name: '',
                    sortable: false,
                    selector: row => row.status,
                    cell: row => <div className="text-center">{(row.status !== "Pagato" && row.link_pagopa !== null) ?
                        <a href={row.link_pagopa} target="_blank"><Badge className={'text-capitalize'} color="primary">
                            PAGA ORA
                        </Badge></a> : null}</div>
                },
            ];


            global.onSetModal({
                content: <div className="login-modal-main">
                    <div className="row no-gutters">
                        <div className="col-12">
                            <div className="row">
                                <div className="heading">
                                    <h3>Dettagli posizione</h3>
                                </div>
                                <DataTable
                                    noHeader
                                    subHeader
                                    responsive
                                    className='react-dataTable'
                                    sortIcon={<i className="fa fa-arrow-down"/>}
                                    columns={columns}
                                    data={iuvs}
                                />
                            </div>
                        </div>
                    </div>
                </div>,
                isOpen: true,
                toggle: true,
                size: 'sm',
                type: 'info',
                buttons: [
                    {label: 'CHIUDI', color: 'danger', onClick: () => global.onSetModal({content: "", isOpen: false})},
                ]
            })


    }

    openTicket(advise) {

        const user = Utils.getUserData();

        const self = this;

        if (advise) {

            global.onSetModal({
                content: <div className="login-modal-main">
                    <div className="row no-gutters">
                        <div className="col-12">
                            <div className="row">
                                <div className="heading">
                                    <h3>Apertura Ticket</h3>
                                </div>
                                <div className="form-group">
                                    <label className="label">Codice Fiscale</label>
                                    <input type="text" disabled className="form-control"
                                           defaultValue={user.fiscal_code}/>
                                </div>

                                <div className="form-group">
                                    <span className='title'>Seleziona categoria:</span>
                                    <Select
                                        className='react-select'
                                        classNamePrefix='select'
                                        value={{value: self.state.form.id_category, label: self.state.form.name_category}}
                                        onChange={(selectedOption, triggeredAction) => {
                                            if (triggeredAction.action === 'clear') {
                                                let form = self.state.form;
                                                form.id_category = null;
                                                form.name_category = "";
                                                self.setState({form: form}, () => self.openTicket(true));
                                            } else {
                                                let form = self.state.form;
                                                form.id_category = selectedOption.value;
                                                form.name_category = selectedOption.label;
                                                self.setState({form: form}, () => self.openTicket(true));
                                            }
                                        }}
                                        options={global.municipalityData.categories.map((category, i) => {return {value: category.id, label: category.name}})}
                                        isClearable={true}
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="label">Oggetto</label>
                                    <input type="text" value={this.state.form.object} disabled className="form-control"/>
                                </div>

                                <div className="form-group">
                                    <label className="label">Messaggio</label>
                                    <textarea rows={8} onChange={(e) => {
                                        let form = self.state.form;
                                        form.message = e.target.value;
                                        self.setState({form: form}, () => self.openTicket(true))
                                    }} className="form-control"/>
                                </div>

                                {(self.state.errorModal !== null) ?
                                    <Alert color="danger">{self.state.errorModal}</Alert> : null}
                            </div>
                        </div>
                    </div>
                </div>,
                isOpen: true,
                toggle: true,
                size: 'sm',
                type: 'info',
                buttons: [
                    {label: 'APRI TICKET', color: 'success', onClick: () => this.openTicket(false)},
                    {
                        label: 'ANNULLA', color: 'danger', onClick: () => this.setState({
                            form: {
                                id_category: null,
                                name_category: "",
                                object: "",
                                message: ""
                            }
                        }, () => global.onSetModal({isOpen: false, content: ""}))
                    },
                ]
            })
        } else {
            let form = this.state.form;
            let error = null;

            if (form.id_category === null)
                error = "Devi selezionare prima la categoria";

            if (form.object === "")
                error = "L'oggetto è obbligatorio";

            if (error === null) {
                this.setState({loading: true});

                TicketAPI.openTicket({
                    id_category: form.id_category,
                    object: form.object
                }).then((ticket) => {
                    console.log("ticket", ticket);
                    TicketAPI.sendMessage({
                        id: ticket.id,
                        message: form.message
                    }, null).then((ticketMessage) => {
                        this.setState({loading: false});
                        global.onSetModal({isOpen: false});
                        console.log(ticketMessage)
                        window.location.href = "/site/" + global.getMunicipalityString() + "/ticket/" + ticket.id;
                    }).catch((e) => {
                        console.log(e);
                        this.setState({errorModal: "Si è verificato un errore durante l'apertura del ticket"}, () => this.openTicket(true));
                    });
                }).catch((e) => {
                    console.log(e);
                    this.setState({errorModal: "Si è verificato un errore durante l'apertura del ticket"}, () => this.openTicket(true));
                });
            } else {
                this.setState({errorModal: error}, () => this.openTicket(true))
            }
        }
    }

    

    async openInstallment(advise) {
        const formatDate = () => {
            const dd = new Date();
            const year = dd.getFullYear();
            const month = String(dd.getMonth() + 1).padStart(2, '0'); 
            const day = String(dd.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate
        }
        const user = Utils.getUserData();
        const self = this;

        if (advise) {

            global.onSetModal({
                content: <div className="login-modal-main">
                    <div className="row no-gutters">
                        <div className="col-12">
                            <div className="row">
                                <div className="heading">
                                    <h4>Rateizza l'importo di € {self.state.form.remaining_payment} per la pratica {this.state.formInstallment.object}</h4>
                                </div>                                
                                <div className="form-group">
                                    <label className="label">Codice Fiscale</label>
                                    <input type="text" disabled className="form-control" defaultValue={user.fiscal_code}/>
                                </div>
                                <div className="form-group">
                                    <span className='title'>Categoria:</span>
                                    <input type="text" disabled className="form-control" defaultValue={self.state.formInstallment.name_category}/>                                    
                                </div>
                                <Select
                                        className='react-select'
                                        classNamePrefix='select'
                                        value={                                            
                                            {
                                                value: self.state.formInstallment.installment_expiration_id, label: self.state.formInstallment.installment_expiration
                                            }
                                        }
                                        onChange={(selectedOption, triggeredAction) => {                                            
                                            if (triggeredAction.action === 'clear') {
                                                let formInstallment = self.state.formInstallment;
                                                formInstallment.id_category = null;
                                                formInstallment.name_category = "";
                                                self.setState({formInstallment: formInstallment}, () => self.openInstallment(true));
                                            } else {
                                                let formInstallment = self.state.formInstallment;
                                                formInstallment.installment_expiration_id = selectedOption.value;
                                                formInstallment.installment_expiration = selectedOption.label;
                                                self.setState({formInstallment: formInstallment}, () => self.openInstallment(true));
                                            }
                                        }}
                                        options = {[{value: 1, label: '15 del mese'},{value: 2, label: 'Ultimo del mese'}]}
                                        isClearable={true}
                                    />
                                <div className="form-group">
                                    <label className="label">Numero rate:</label>
                                    <input type="number" className="form-control" onChange={this.handleInstNum} />
                                </div>                                

                                {(self.state.errorModal !== null) ?
                                    <Alert color="danger">{self.state.errorModal}</Alert> : null}
                            </div>
                        </div>
                    </div>
                </div>,
                isOpen: true,
                toggle: true,
                size: 'sm',
                type: 'info',
                buttons: [
                    {
                        label: 'RATEIZZA IL TUO IMPORTO', color: 'success', onClick: () => {
                        this.openInstallment(false)
                        this.resetFormInstallment();
                        }

                    },
                    {
                        label: 'ANNULLA', color: 'danger', onClick: () => {
                            this.state.formInstallment.installment_nr = null;
                            this.state.formInstallment.installment_expiration = null;
                            this.setState({errorModal: null});
                            global.onSetModal({isOpen: false, content: ""});
                        }
                    },
                ]
            })
        } else {
            let formInstallment = this.state.formInstallment;
            let error = null;
            this.setState({errorModal: null});

            if (!formInstallment.installment_nr){
                error = "Devi inserire il numero di rate";
            }
            if (!formInstallment.installment_expiration_id){
                error = "Devi inserire la scadenza della rata";
            }

            if (!error) {
                this.setState({loading: true});
                const payments_notice_id = this.state.formInstallment.object.replace('Pratica N. ', '');
                const number_installments = this.state.formInstallment.installment_nr;
                const expiration_interval = this.state.formInstallment.object.replace('Pratica N. ', '');
                const expiration_day = this.state.formInstallment.installment_expiration.id === 1 ? '15' : 'FINE';
                const date = formatDate();

                let body = {
                    "payments_notice_id" : payments_notice_id,
                    "number_installments": number_installments,
                    "expiration_interval" : 1,
                    "expiration_day" : expiration_day,
                    "date" : date
                }
                console.log(this.state.formInstallment);
                console.log('Body chiamata: ', body);
                let result = await InstallmentApi.postInstallment(body);
                if (result) {
                    console.log('success');                    
                }
                this.setState({loading: false});
                global.onSetModal({isOpen: false})
            } else {
                this.setState({errorModal: error}, () => this.openInstallment(true))
            }
        }        
    }

    

    openIuv(row) {

        const user = Utils.getUserData();

        const self = this;

        PaymentsAPI.openIuv({payments_notice_id:row.payments_notice_id}).then( (iuv) => {
            global.onSetModal({loading: false, content: "", isOpen: false});
            global.SweetAlert.fire({
                title: 'Richiesta avviso di Pagamento eseguita correttamente !',
                text: '',
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500}).then(function (result) {
                    window.location.href = "/site/" + global.getMunicipalityString() + "/pagamenti";
                    }).catch((e) => {
                    console.log("err message", e);
                    this.setState({errorModal: "Si è verificato un errore durante la richiesta avviso di pagamento"}, () => {
                        window.location.href = "/site/" + global.getMunicipalityString() + "/pagamenti";})})})
        // IuvAPI.openIuv({
        //     id_category : form.id_category,
        //     object: form.object
        // }).then().then().catch();
        
    }

    render() {
        const statusObj = {
            DELAYED: {color: 'text-danger', label: 'SCADUTO'},
            TOPAY: {color: 'text-warning', label: 'DA PAGARE'},
            Pagato: {color: 'text-success', label: 'Pagato'}
        };

        const columns = [
            {
                name: 'Numero Pratica',
                sortable: false,
                sortField: 'prot_int_atto',
                minWidth: "140px",
                selector: row => row.prot_int_atto,
                cell: row => (
                    <div className='d-flex justify-content-left align-items-center'>
                        <div className='d-flex flex-column'>
                            <strong>{row.prot_int_atto}</strong>
                        </div>
                    </div>
                )
            },
            {
                name: '',
                sortable: false,
                maxWidth: "10px",
                minWidth: "10px",
                cell: row => (
                    <div>
                        <strong>{(row.url_doc !== null) ?
                            <a href={row.url_doc} target="_blank"><i className="fa-solid fa-file-pdf text-danger"
                                                                     style={{fontSize: "20px", cursor: "pointer"}}
                                                                     title="Visualizza Documento"/></a> : null}</strong>
                    </div>
                )
            },
            {
                name: 'Tipo di atto',
                sortable: false,
                sortField: 'object',
                maxWidth: "200px",
                minWidth: "200px",
                selector: row => row.object,
                cell: row => (
                    <div className='d-flex justify-content-left align-items-left'>
                        {row.object}
                    </div>
                )
            },
            {
                name: 'Entrata',
                sortable: false,
                selector: row => row.id_category,
                cell: row => (
                    <div className='d-flex justify-content-left align-items-center'>
                        <div className='d-flex flex-column'>
                            <span className='fw-bolder text-nowrap'>{Utils.getCategoryName(row.id_category)}</span>
                        </div>
                    </div>
                )
            },
            {
                name: 'Anno',
                sortable: false,
                selector: row => row.year,
                cell: row => (
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='d-flex flex-column'>
                            <span className='fw-bolder text-nowrap'>{row.year}</span>
                        </div>
                    </div>
                )
            },
            {
                name: 'Dovuto',
                sortable: false,
                sortField: 'total',
                selector: row => row.total,
                cell: row => <p>{Utils.getValueMoneyLabel(row.total)}</p>
            },
            {
                name: 'Pagato',
                sortable: false,
                sortField: 'paid',
                selector: row => row.paid,
                cell: row => <p>{Utils.getValueMoneyLabel(row.paid)}</p>
            },
            {
                name: 'Residuo',
                sortable: false,
                sortField: 'remaining_payment',
                selector: row => row.remaining_payment,
                cell: row => <span className="text-right">{(String(row.remaining_payment).indexOf("Totale") > -1) ?
                    <p className="text-nowrap"><strong>{row.remaining_payment}</strong></p> :
                    <p>{Utils.getValueMoneyLabel(row.remaining_payment)}</p>}</span>
            },

            // {
            //     name: 'Rischiesta Iuv',
            //     sortable: false,
            //     selector: row => row.status,
            //     cell: row => <div className='justify-content-center align-items-center'>
            //         <span>
            //             <Badge color="primary" style={{fontSize: "15px", cursor: "pointer"}}
            //                    onClick={() => this.openIuv(row)}><i
            //                 className="fa fa-solid fa-receipt" style={{fontSize: "12px"}} title="Richiesta avviso pagamento"/></Badge>
            //                 </span>
            //     </div>
            // },

            {
                name: 'Richiesta PagoPa',
                sortable: false,
                selector: row => row.status,
                cell: row => <div className='justify-content-center align-items-center'>
                    {(typeof row.abilitatazione_richiesta_avvisi_pagopa === "undefined" && typeof row.abilitatazione_richiesta_avvisi_pagopa === "false") ? <span>{(row.iuvs.length == 0) ?
                        <Badge color="primary" style={{fontSize: "15px", cursor: "pointer"}}
                               onClick={() => this.openIuv(row)}><i
                            className="fa fa-solid fa-receipt" style={{fontSize: "12px"}} title="Richiesta PagoPa"/></Badge> : null}</span> : null }
                </div>
            },
            {
                name: 'Richiesta rateazione',
                sortable: false,
                selector: row => row.rateizzabile,
                cell: row => row.rateizzabile ? 
                <div>
                    <button className='btn'>
                        <span className='text-capitalize badge bg-primary' onClick={() => this.setState({
                               formInstallment: {
                                   id_category: row.id_category,
                                   name_category: Utils.getCategoryName(row.id_category),
                                   object: "Pratica N. " + row.prot_int_atto,
                                   message: "",
                                   remaining_payment: row.remaining_payment
                               }
                           }, () => this.openInstallment(true))}>Rateizza</span>
                    </button>
                </div>
                : 
                <div></div>
            },
            {
                name: 'Dettagli',
                sortable: false,
                selector: row => row.status,
                cell: row => <div className='justify-content-center align-items-center'>
                    {(typeof row.iuvs !== "undefined") ? <span>{(row.iuvs.length > 0) ?
                        <Badge color="primary" style={{fontSize: "15px", cursor: "pointer"}}
                               onClick={() => this.getDetailsPayment(row.iuvs)}><i
                            className="fa fa-search text-white" style={{fontSize: "12px"}} title="Dettaglio pagamento"/></Badge> : null}</span> : null }
                </div>
            },
            {
                name: 'Stato',
                sortable: false,
                selector: row => row.status,
                cell: row => <div>{(row.status !== "Pagato" && typeof row.remaining_payment !== "string") ?
                    <small className="text-primary" style={{fontSize: "11px", cursor: "pointer"}}
                           onClick={() => this.setState({
                               form: {
                                   id_category: row.id_category,
                                   name_category: Utils.getCategoryName(row.id_category),
                                   object: "Pratica N. " + row.prot_int_atto,
                                   message: ""
                               }
                           }, () => this.openTicket(true))}>Serve aiuto?</small> : null}<br/><Badge
                    className={'text-capitalize'}
                    color={(row.status === "Pagato") ? "success" : ((row.status === "Non Pagato") ? "danger" : "secondary")}>
                    {row.status}
                </Badge></div>
            },
            // {
            //     name: '',
            //     sortable: false,
            //     selector: row => row.status,
            //     cell: row => <div className="text-center">{(row.status !== "Pagato" && row.link_pagopa !== null) ?
            //         <a href={row.link_pagopa} target="_blank"><Badge className={'text-capitalize'} color="primary">
            //             PAGA ORA
            //         </Badge></a> : null}</div>
            // },
            // {
            //     name: 'Rottamabile',
            //     sortable: false,
            //     selector: row => row.pratica_rottamabile,
            //     cell: row => <div className="text-center">{(row.pratica_rottamabile
            //         ===  true) ?
            //         <Badge className={'text-capitalize'} color="success">
            //             R
            //         </Badge> : null}</div>
            // }

        ];

        return (
            <div className="text-center">
                {(this.state.loading) ? <Spinner/> : <div>
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h2 data-wow-delay=".4s" className="wow fadeInUp animated"
                                    style={{visibility: "visible", animationDelay: 0.4}}>Posizioni contribuente</h2>
                                {/*<p data-wow-delay=".6s" className="wow fadeInUp animated"*/}
                                {/*   style={{visibility: "visible", animationDelay: 0.6}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the standard.</p>*/}
                            </div>
                        </div>
                    </div>

                    {(this.state.error) ? <div><Alert color="danger">Devi effettuare il login per avere accesso a questa
                        pagina</Alert><br/><LoginButton/></div> : <div className='react-dataTable'>
                        <DataTable
                            noHeader
                            subHeader
                            pagination
                            responsive
                            className='react-dataTable'
                            sortIcon={<i className="fa fa-arrow-down"/>}
                            columns={columns}
                            data={this.state.payments}
                            paginationComponentOptions={{
                                rowsPerPageText: 'Pagamenti per pagina',
                                rangeSeparatorText: 'di'
                            }}
                            subHeaderComponent={
                                <Row>
                                    <Col xl='6' style={{width: "100%"}}>
                                        <Input
                                            placeholder="Cerca posizione"                                            
                                            type='text'
                                            onChange={e => this.onSearchAdvise(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            }
                        />
                    </div>}
                </div>}
                <InstallmentModal />
            </div>
        );
    }
}

export default Payments;
