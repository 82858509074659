/**
 * Created by Pasquale Tritella on 22/04/2024
 *
 * @format
 */


const postInstallment = async (body) => {
    const hash = localStorage.getItem('hash');
        if (hash === null) {
            console.error("NO_AUTH");
            return;
        }
    const link = global.route.installment;
    try {
        const response = await fetch(link, {
          method: 'POST',
          headers: {'x-access-token': hash},
          body: JSON.stringify(body),
          mode: 'no-cors'
        });  
        if (!response.ok) {
            console.log(response);
          throw new Error('Network response was not ok');
        }        
        const data = await response.json();
        console.log('Success:', data);
      } catch (error) {
        console.error('Error:', error);
      }

    return false;
}

const InstallmentApi = {
    postInstallment: postInstallment
};

module.exports = InstallmentApi;
