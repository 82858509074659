import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

class InstallmentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: {
                isOpen: false,
                title: '',
                subtitle: '',
                content: '',
                buttons: [],
                toggle: true,
                className: '',
                loading: false,
                loadingText: '',
                size: 'md',
                type: ''
            }
        };

        this.toggle = this.toggle.bind(this);

        const self = this;

        global.onSetModal = function (options) {
            let modal = self.state.modal;
            let arrayKeys = Object.keys(options);
            for (let i = 0; i < arrayKeys.length; i++) {
                modal[arrayKeys[i]] = options[arrayKeys[i]];
            }

            self.setState({modal: modal});
        };
    }

    toggle() {
        let modal = this.state.modal;
        modal.isOpen = (!modal.isOpen);
        this.setState({ modal: modal });
    }

    render() {
        return (
            <Modal isOpen={this.state.modal.isOpen} toggle={(this.state.modal.toggle) ? this.toggle : undefined} className='modal-dialog-scrollable modal-lg'>
                <ModalHeader className='bg-transparent' toggle={(this.state.modal.toggle) ? this.toggle : undefined} />
                <ModalBody className='px-sm-5 pt-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>{this.state.modal.title}</h1>
                        <p>{this.state.modal.subtitle}</p>
                    </div>
                    {this.state.modal.content}
                </ModalBody>
                <ModalFooter>
                    {this.state.modal.buttons.map(option => (
                        <Button key={option.label} color={option.color} disabled={option.disabled} icon={'close'}
                                onClick={option.onClick}>{option.label}</Button>
                    ))}
                </ModalFooter>
            </Modal>
        )
    }
}

export default InstallmentModal;
