const fakedb = require("../fakedb.json");
const moment = require("moment");
/**
 * Created by Federico Brocco on 13/05/2022.
 *
 * @format
 */

let listAppointments = function (idCategory, month, day) {
    return new Promise(function (resolve, reject) {

        const hash = localStorage.getItem('hash');

        if (hash === null) {
            reject("NO_AUTH");
            return;
        }

        fetch(global.route.appointments, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API appointments', responseJson);
                resolve(responseJson.appointments);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });


    });
};

let listSlots = function (idCategory, month, day) {
    return new Promise(function (resolve, reject) {

        const hash = localStorage.getItem('hash');

        if (hash === null) {
            reject("NO_AUTH");
            return;
        }
        const link = global.route.availability + "?id_category=" + idCategory + "&month=" + month + ((typeof day !== "undefined") ? "&day=" + day : '')

        console.log(link)
        fetch(link, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API availability', responseJson);
                resolve(responseJson.availability);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });


    });
};

let bookingSlot = function (availability_id) {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');

        if (hash === null) {
            reject("NO_AUTH");
            return;
        }

        const link = global.route.booking + "?availability_id=" + availability_id;

        fetch(link, {
            headers: {'x-access-token': hash},
            method: 'POST'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API bookingSlot', responseJson);
                if (typeof responseJson.availability.availability_id !== "undefined")
                    resolve(responseJson.availability);

                reject()
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};



const AppointmentsAPI = {
    listSlots: listSlots,
    bookingSlot: bookingSlot,
    listAppointments: listAppointments
};

module.exports = AppointmentsAPI;
