import React, {Component} from 'react';
import {Route, Switch, BrowserRouter} from "react-router-dom";

import {Spinner, CustomModal} from './components/';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {AuthAPI} from "./api";
import {Utils} from "./services";
// import fakedb from "./fakedb.json";

// Containers
const Layout = React.lazy(() => import('./containers/Layout'));

global.SweetAlert = withReactContent(Swal);

class App extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <React.Suspense fallback={<Spinner/>}>
                <Layout/>
                <CustomModal/>
            </React.Suspense>
        );
    }
}

export default App;
