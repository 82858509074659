import React, {Component} from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'TailSpin',
            label: 'Caricamento..',
            color: '#FF9F43',
            height: '100',
            width: '100'
        };
    }

    componentWillMount() {
        this.setState({
            type: (typeof this.props.type === 'undefined') ? 'TailSpin' : this.props.type,
            label: (typeof this.props.label === 'undefined') ? 'Caricamento..' : this.props.label,
            color: (typeof this.props.color === 'undefined') ? '#FF9F43' : this.props.color,
            height: (typeof this.props.height === 'undefined') ? '100' : this.props.height,
            width: (typeof this.props.width === 'undefined') ? '100' : this.props.width
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(nextProps);
    }

    render() {

        return <span></span>
    }
}

export default Loading;
