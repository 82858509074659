import SpinnerComponent from './Fallback-spinner';
import LoadingComponent from './Loading';
import CustomModalComponent from './CustomModal';
import InstallmentModalComponent from './InstallmentModal';
import LoginButtonComponent from './LoginButton';

export const Spinner = SpinnerComponent;
export const Loading = LoadingComponent;
export const CustomModal = CustomModalComponent;
export const InstallmentModal = InstallmentModalComponent;
export const LoginButton = LoginButtonComponent;

