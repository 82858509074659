import React, {Component} from 'react';
import Loader from 'react-loader-spinner';

import {Redirect} from "react-router-dom";

import {Alert} from "reactstrap"

import 'typeface-titillium-web';
import {SPIDReactButton} from '@dej611/spid-react-button'
import '@dej611/spid-react-button/dist/index.css'
import {AuthAPI} from "../api";

import {Utils} from "../services";




class LoginButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                fiscal_code: "",
                password: ""
            },
            error: null,
            authenticated: false,
            loading: false
        }
    }


    onChangeForm(key, e) {
        const form = this.state.form;
        form[key] = e.target.value;
        this.setState({form: form}, () => this.showModalLogin());
    }


    doLogin() {

        const self = this;

        let form = this.state.form;
        let error = null;
        if (form.password === "") {
            error = "La password non può essere vuota";
        }

        if (form.fiscal_code.length === 16) {
            if (form.fiscal_code !== "" && !Utils.isValidFiscalCode(form.fiscal_code)) {
                error = 'Il codice fiscale inserito non è valido';
            }
        }

        if (form.fiscal_code === "") {
            error = 'Inserisci il tuo codice fiscale o username per continuare';
        }

        this.setState({error: error}, () => this.showModalLogin());

        if (error === null) {
            this.setState({loading: true}, () => this.showModalLogin());
            AuthAPI.login(this.state.form).then((user) => {
                this.setState({authenticated: true});
                global.onSetModal({loading: false, content: "", isOpen: false});
                global.SweetAlert.fire({
                    title: 'Bentornato,' + `${user.firstname ?? ''} ${user.lastname ?? ''}`+ "!",
                    text: '',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 1500
                }).then(function (result) {
                    if (Utils.isOperator()) {
                        window.location.assign(process.env.REACT_APP_FRONTEND_URL + "/" + global.municipalityData.string + "/dashboard");
                    } else {
                        window.location.reload();
                    };

                });
            }).catch(() => {
                this.setState({loading: false, error: "Accesso negato"}, () => this.showModalLogin());
            });
        }
    }


    showModalLogin(){
        const municipality = this.props.municipality;
        
        let form = this.state.form;
        let errors = this.state.errors;
        let app_aggregator_url = 'https://utd-' + global.getMunicipalityString().replace('_','-') + '.megtributi.it';
        console.log(app_aggregator_url);

        global.onSetModal({
            content: <div className="login-modal-main">
                    <div className="row no-gutters">
                        <div className="col-12">
                            <div className="row">
                                <div className="heading">
                                    <h3>Accesso Contribuente</h3>
                                    <p>Accedi per consultare la tua posizione contributiva ed ulteriori servizi</p>
                                </div>
                                {/* <div className="text-center"> */}
                                <div className="spid-button">
                                    <SPIDReactButton 
                                        url={app_aggregator_url + "/servizispid/LoginSPID?entityID={{idp}}&target=" + app_aggregator_url + "/site/" + global.getMunicipalityString() + "?loginspid=true&authnContextClassRef=https://www.spid.gov.it/SpidL2"}
                                        method={"GET"}
                                        supported={[
                                            "https://loginspid.aruba.it",
                                            "https://identity.infocert.it",
                                            "https://spid.intesa.it",
                                            "https://id.lepida.it/idp/shibboleth",
                                            "https://idp.namirialtsp.com/idp",
                                            "https://posteid.poste.it",
                                            "https://spid.register.it",
                                            "https://spid.teamsystem.com/idp",
                                            "https://identity.sieltecloud.it",
                                            "https://login.id.tim.it/affwebservices/public/saml2sso"
                                        ]}
                                        // corners={"sharp"}
                                        // supported={[
                                        //     "https://demo.spid.gov.it/validator"
                                        // ]}
                                        // extraProviders={[
                                        //     {
                                        //         "entityID": "https://demo.spid.gov.it/validator",
                                        //         "entityName": "Test IdP"
                                        //     }
                                        // ]}
                                        protocol="SAML"
     

                                    />
                                </div>
                                <div className="or-devider">
                                    <span>oppure</span>
                                </div>
                                <div className="form-group">
                                    <label className="label">Codice Fiscale / username</label>
                                    <input type="text" value={this.state.form.fiscal_code} className="form-control" onChange={(e) => this.onChangeForm('fiscal_code', e)}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password" className="label">Password</label>
                                    <div className="position-relative">
                                        <input type="password" value={this.state.form.password} className="form-control" id="password" onChange={(e) => this.onChangeForm('password', e)}/>
                                    </div>
                                </div>
                                {(this.state.error !== null) ? <Alert color="danger">{this.state.error}</Alert> : null}
                                <div className="form-group d-flex flex-wrap justify-content-between">
                                    <a href="#" className="font-size-3 text-dodger line-height-reset">Password dimenticata?</a>
                                </div>
                                <div className="form-group mb-8 button">
                                    <button className="btn " onClick={(e) => this.doLogin(e)}>
                                         {(this.state.loading) ? <i className="fa fa-lg fa-spin fa-spinner" /> : "Accedi" }
                                    </button>
                                </div>
                                {   
                                    ((this.props.municipality.email != null) && (this.props.municipality.telefono != null)) ?
                                    (<p className="text-center create-new-account">
                                        {/* Non hai un account?<br />Contatta <a href={'mailto:' + process.env.REACT_APP_EMAIL_ASSISTANCE}>{process.env.REACT_APP_EMAIL_ASSISTANCE}</a> per creare un account */}
                                        Non hai un account?
                                        <br />Contatta l'indirizzo <a href={'mailto:' + this.props.municipality.email}>{this.props.municipality.email}</a> oppure il numero <a href={'tel:' + this.props.municipality.telefono}>{this.props.municipality.telefono}</a> per creare un account
                                    </p>) : null
                                }
                                {   
                                    ((this.props.municipality.email != null) && (this.props.municipality.telefono == null)) ?
                                    (<p className="text-center create-new-account">
                                        Non hai un account?
                                        <br />Contatta l'indirizzo <a href={'mailto:' + this.props.municipality.email}>{this.props.municipality.email}</a>
                                    </p>) : null
                                }
                                {   
                                    ((this.props.municipality.email == null) && (this.props.municipality.telefono != null)) ?
                                    (<p className="text-center create-new-account">
                                        Non hai un account?
                                        <br />Contatta il numero <a href={'tel:' + this.props.municipality.telefono}>{this.props.municipality.telefono}</a> per creare un account
                                    </p>) : null
                                }

                            </div>
                        </div>
                    </div>
                </div>,
            isOpen: true,
            toggle: true,
            size: 'sm',
            type: 'info',
            buttons: []
        })
    }

    render() {
        if (this.state.authenticated)
            return <Redirect to={"/site/" + global.getMunicipalityString()}/>

        return <a className="login" style={{cursor: "pointer"}} onClick={() => this.setState({loading: false, error: null}, () => this.showModalLogin())}>
                <i className="lni lni-lock-alt" /> Login
        </a>;
    }
}

export default LoginButton;
