const fakedb = require("../fakedb.json");
const advise = require("../views/Payments");
/**
 * Created by Federico Brocco on 13/05/2022.
 *
 * @format
 */

let list = function () {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');

        if (hash === null) {
            reject("NO_AUTH");
            return;
        }

        fetch(global.route.payments, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API payments_notice', responseJson.payments_notice);
                resolve(responseJson.payments_notice);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let detail = function (payments_notice_id) {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');

        if (hash === null) {
            reject("NO_AUTH");
            return;
        }

        fetch(global.route.paymentsDetail + "?payment_notice_id=" + payments_notice_id, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API payments_notice_detail', responseJson);
                resolve(responseJson.payments);
            })
            .catch((e) => {
                console.error(e.message);
                reject  ()
            });
    });
};

// PaymentsAPI.openIuv({payments_notice_id:row.payments_notice_id});
let openIuv= function (row) {//console.log('here');console.log(row.payments_notice_id);
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');
        if (hash === null) {
            reject("NO_AUTH");
            return;
        }

        fetch(global.route.bookingIuv, {
            headers: {'x-access-token': hash},
            method: 'POST',
            body: JSON.stringify({
                payments_notice_id: row.payments_notice_id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API openIuv', responseJson);
                resolve(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

const paymentsAPI = {
    list: list,
    detail: detail,
    openIuv: openIuv 
};

module.exports = paymentsAPI;
