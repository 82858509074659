/**
 * Created by Federico Brocco on 07/12/2023.
 *
 * @format
 */

let list = function () {
    return new Promise(function (resolve, reject) {
        fetch(global.route.modules, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('chiamata api: ', global.route.modules);
                console.log('API modules list', responseJson);
                resolve(responseJson.tipi_modulistica);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let detail = function (module_type_id) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.module_detail + "?tipo_modulistica_id=" + module_type_id, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API module detail', responseJson.moduli);
                resolve(responseJson.moduli);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};


const ModuleFilesAPI = {
    list: list,
    detail: detail
};

module.exports = ModuleFilesAPI;
