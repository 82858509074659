import _AuthAPI from './AuthAPI';
import _MunicipalityAPI from './MunicipalityAPI';
import _TicketAPI from './TicketAPI';
import _PaymentsAPI from './PaymentsAPI';

import _AppointmentsAPI from './AppointmentsAPI';

import _CategoriesAPI from './CategoriesAPI';
import _RegulationsAPI from './RegulationsAPI';

import _LawsAPI from './LawsAPI';

import _ModulesAPI from './ModulesAPI';
import _ModuleFilesAPI from './ModuleFilesAPI';

import _InstallmentApi from './InstallmentApi'


export const AuthAPI = _AuthAPI;
export const MunicipalityAPI = _MunicipalityAPI;
export const TicketAPI = _TicketAPI;
export const PaymentsAPI = _PaymentsAPI;

export const AppointmentsAPI = _AppointmentsAPI;
export const CategoriesAPI = _CategoriesAPI;

export const RegulationsAPI = _RegulationsAPI;

export const LawsAPI = _LawsAPI;

export const ModulesAPI = _ModulesAPI;
export const ModuleFilesAPI = _ModuleFilesAPI;

export const InstallmentApi = _InstallmentApi;


