/**
 * Created by Federico Brocco on 13/05/2022.
 *
 * @format
 */

let list = function () {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');

        if (hash === null) {
            reject("NO_AUTH");
            return;
        }

        fetch(global.route.categories, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log('API categories', responseJson);
                resolve(responseJson.category);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};


const categoriesAPI = {
    list: list
};

module.exports = categoriesAPI;
