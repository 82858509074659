
let isValidEmail = function (text) {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(text);
};

let isValidPassword = function (text) {
    const reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
    return reg.test(text);
};

let isValidPhone = function (text) {
    let reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    // let reg = /^[0-9]{5,10}$/;
    return reg.test(text);
};

let isValidFiscalCode = function (text) {
    let reg = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i;
    return reg.test(text);
};

let isValidIban = function (text) {
    let reg = /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i;
    return reg.test(text);
};


let isValidPiva = function (text) {
    let reg = /^[0-9]{11}$/;
    return reg.test(text);
};

let isValidSwiftCode = function (text) {
    let reg = /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/;
    return reg.test(text);
};

let isPercentage = function (number) {
    if (isNaN(number))
        return false;

    return (number >= 0 && number <= 100);
};

let isLoggedIn = function () {
    let hash = localStorage.getItem("hash");
    return (hash !== null);
};

let isOperator = function () {
    let userData = global.userData;
    if (typeof global.userData === "undefined")
        return false
    return (typeof userData.operator !== "undefined");
};

let getUserData = function () {
    return JSON.parse(sessionStorage.getItem("userData"));
};


let getValueMoneyLabel = function (number) {
    if (number === null) {
        return '0,00 €';
    }

    if (typeof number !== 'number') {
        return '';
    }
    return String(number.toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') +  ' €';
};

let getCategoryName = function (id_category) {
    let categories = global.categories;

    if (typeof categories !== "undefined") {
        for (let i = 0; i < categories.length; i++) {
            if (id_category === categories[i].id) {
                return categories[i].name
            }
        }
    }

    return "";
}

let getCategories = function () {
    let categories = global.categories;

    if (typeof categories !== "undefined" && categories !== null ) {
        return categories;
    }

    return [];
}


const Utils = {
    isValidEmail: isValidEmail,
    isValidPassword: isValidPassword,
    isValidPhone: isValidPhone,
    isValidFiscalCode: isValidFiscalCode,
    isValidIban: isValidIban,
    isValidSwiftCode: isValidSwiftCode,
    isValidPiva: isValidPiva,
    isLoggedIn: isLoggedIn,
    getUserData: getUserData,
    isPercentage: isPercentage,
    getValueMoneyLabel: getValueMoneyLabel,
    isOperator: isOperator,
    getCategoryName: getCategoryName,
    getCategories: getCategories
};

module.exports = Utils;
