/**
 * Created by Federico Brocco on 13/05/2022.
 *
 * @format
 */

let detail = function () {
    console.log('chiamata api: ', global.route.homepage);
    return new Promise(function (resolve, reject) {
        fetch(global.route.homepage, {
            method: 'GET'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                try{
                    global.municipalityData = responseJson;
                    global.categories = responseJson.categories;
                    resolve(responseJson);
                }catch (e){
                    console.error(e.message);
                    reject()
                }
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

const MunicipalityAPI = {
    detail: detail
};

module.exports = MunicipalityAPI;
